export type ColorType = 'custom' | 'primary' | 'success' | 'secondary' | 'error';
export type ColorRange = 'invert' | 'soft' | 'normal';

export const focusVisibleDefault = 'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 ';
export const focusPeerVisibleDefault = 'peer-focus-visible:outline-none peer-focus-visible:ring-2 peer-focus-visible:ring-offset-2';

export const borderInvert: Record<ColorType, string> = {
  custom: '',
  primary: /* tw */  'border border-gg-blue-600',
  success: /* tw */ 'border border-positive',
  secondary: /* tw */ 'border border-gray-600',
  error: /* tw */ 'border border-negative',
};

export const borderInvertText: Record<ColorType, string> = {
  custom: '',
  primary: /* tw */  'text-gg-blue-600 bg-white',
  success: /* tw */ 'text-gg-green-600 bg-white',
  secondary: /* tw */ 'text-gray-600 bg-white',
  error: /* tw */ 'text-negative bg-white',
};

export const borderInvertHover: Record<ColorType, string> = {
  custom: '',
  primary: /* tw */  'hover:border-primary hover:text-primary',
  success: /* tw */ 'hover:border-gg-green-600 hover:text-gg-green-600',
  secondary: /* tw */ 'hover:border-gray-700 hover:text-gray-700',
  error: /* tw */ 'hover:border-negative-darker hover:text-negative-darker',
};

export const softText: Record<ColorType, string> = {
  custom: '',
  primary: /* tw */  'text-gg-blue-600',
  success: /* tw */ 'text-gg-green-600',
  secondary: /* tw */ 'text-gray-600',
  error: /* tw */ 'text-negative',
};

export const softBackground: Record<ColorType, string> = {
  custom: '',
  primary: /* tw */ 'bg-gg-blue-100',
  success: /* tw */ 'bg-gg-green-50',
  secondary: /* tw */ 'bg-gray-100',
  error: /* tw */ 'bg-red-100',
};
export const softBackgroundHover: Record<ColorType, string> = {
  custom: '',
  primary: /* tw */ 'hover:bg-gg-blue-300',
  success: /* tw */ 'hover:bg-gg-green-100',
  secondary: /* tw */ 'hover:bg-gray-300',
  error: /* tw */ 'hover:bg-red-300',
};

export const background: Record<ColorType, string> = {
  custom: '',
  primary: /* tw */ 'border border-gg-blue-600 bg-gg-blue-600 text-white',
  success: /* tw */ 'border border-positive text-white bg-positive',
  secondary: /* tw */ 'border border-gray-500 text-white bg-gray-500',
  error: /* tw */ 'border border-negative text-white bg-negative',
};
export const backgroundHover: Record<ColorType, string> = {
  custom: '',
  primary: /* tw */ 'hover:bg-gg-blue-700',
  success: /* tw */ 'hover:bg-gg-green-600',
  secondary: /* tw */ 'hover:bg-gray-300',
  error: /* tw */ 'hover:bg-negative-darker',
};

export const text: Record<ColorType, string> = {
  custom: '',
  primary: /* tw */ 'text-gg-blue-600',
  success: /* tw */ 'text-positive',
  secondary: /* tw */ 'text-gray-400',
  error: /* tw */ 'text-negative',
};
export const textHover: Record<ColorType, string> = {
  custom: '',
  primary: /* tw */ 'hover:text-gg-blue-700',
  success: /* tw */ 'hover:bg-gg-green-600',
  secondary: /* tw */ 'hover:text-gray-300',
  error: /* tw */ 'hover:text-negative-darker',
};

export const focus: Record<ColorType, string> = {
  custom: '',
  primary: /* tw */ 'focus-visible:ring-gg-blue-600',
  success: /* tw */ 'focus-visible:ring-positive',
  secondary: /* tw */ 'focus-visible:ring-gray-400',
  error: /* tw */ 'focus-visible:ring-negative',
};

export const focusPeer: Record<ColorType, string> = {
  custom: '',
  primary: /* tw */ 'peer-focus-visible:ring-gg-blue-600',
  success: /* tw */ 'peer-focus-visible:ring-positive',
  secondary: /* tw */ 'peer-focus-visible:ring-gray-400',
  error: /* tw */ 'peer-focus-visible:ring-negative',
};
