<template>
  <Button
    v-bind="$props"
    @click="toogle"
  >
    <slot />
  </Button>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';
import { bool } from 'vue-types';

import Button from '../../Button/Button.vue';
import { buttonProps } from '../../Button/ButtonProperties';

export default defineComponent({
  name: 'ButtonInput',
  components: {
    Button,
  },
  props: {
    modelValue: bool(),
    ...buttonProps,
  },
  emits: ['update:modelValue'],
  setup(properties, { emit }) {
    const inputValue = ref(!!properties.modelValue);
    const toogle = () => {
      inputValue.value = !inputValue.value;
      emit('update:modelValue', inputValue.value);
    };

    return {
      inputValue,
      toogle,
    };
  },
});
</script>
