import { any, func, string } from 'vue-types';

const modalProps = {
  title: string(),
  class: any(),
  deleteClass: Boolean,
  containerClass: any().def('flex min-h-full items-center justify-center'),
  show: Boolean,
  close: func<() => void>(),
  showCloseButton: Boolean,
  dataTestId: string(),
};

export default modalProps;
