import { BrowserConfig } from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

import getBugsnagEnvValues from './getBugsnagEnvValues';
import { MessageError, TokenError, ValidationError } from '@/plugin/ajax/ApiResponse';
import { rentersServerData as serverData } from '@/global/serverData';

const getBugsnagConfig = (apiKey?: string) => {
  const bugsnagConfig = getBugsnagEnvValues(import.meta.env);
  const bugsnagClient: BrowserConfig = {
    apiKey: apiKey ?? bugsnagConfig.apiKey,
    appVersion: bugsnagConfig.appVersion,
    releaseStage: bugsnagConfig.releaseStage,
    enabledReleaseStages: bugsnagConfig.enabledReleaseStages,
    logger: null,
    autoTrackSessions: false,
    plugins: [new BugsnagPluginVue()],
    onError: (event) => {
      if (event.originalError instanceof ValidationError
        || event.originalError instanceof MessageError
        || event.originalError instanceof TokenError
      ) {
        return false;
      }
      if (serverData.session) {
        event.setUser(
          `${serverData.session.id}`,
          serverData.session.email,
          serverData.session.firstName,
        );
      }
      return true;
    },
  };

  return {
    bugsnagConfig,
    bugsnagClient,
  };
};

export default getBugsnagConfig;
