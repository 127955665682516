<script setup lang="ts">
import { ref, onUnmounted } from 'vue';
import { string } from 'vue-types';
import { useMutation } from '@tanstack/vue-query';
import { usePost } from 'use-api-vue';

const props = defineProps({
  class: String,
  classItem: string,
});

const display = ref(true);

const post = usePost({ onCancelCallback: onUnmounted });

const savePrivacyPolicyAcceptance = useMutation({ mutationFn: () => post('/acknowledge_privacy_policy') });

const accept = () => {
  display.value = false;
  savePrivacyPolicyAcceptance.mutate();
};
</script>

<template>
  <div
    :class="[
      'px-2 min-[450px]:px-5 py-px bg-gg-blue-800 text-white rounded text-sm max-w-fit',
      'text-left xs:text-center',
      'whitespace-nowrap',
      { hidden: !display },
      { 'inline-block': display },
      props.class,
    ]"
    role="dialog"
    aria-label="Privacy policy Acknowledgement"
  >
    <span>We’ve updated our privacy policy.&nbsp;&dash;</span>
    <a
      href="/privacy-policy"
      class="text-white mr-2 min-[450px]:mr-4 my-2 inline-block underline hover:no-underline"
      aria-label="Link to updated GradGuard privacy policy"
    >
      Take a look
    </a>
    <button
      class="rounded mx-auto my-2 text-gg-blue-800 bg-white btn-white max-[450px]:w-fit max-[450px]:px-0.5 w-14"
      type="button"
      aria-label="Click to acknowledge GradGuard privacy policy"
      @click="accept"
    >
      Got it
    </button>
  </div>
</template>
