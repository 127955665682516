import {
  any, string, array, bool, object,
} from 'vue-types';

const props = {
  name: string(),
  modelValue: any(),
  class: any(),
  errors: array().def([]),
  defaultClass: any().def('border text-left px-4 py-1 h-10 rounded w-full'),
  errorClass: any().def('border-red-500'),
  noErrorClass: any().def('border-gray-400'),
  required: bool(),
  availables: object<Record<string, boolean>>(),
};

export default props;
