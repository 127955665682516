<template>
  <ul
    :id="resultId"
    class="space-y-2"
    role="listbox"
    :aria-activedescendant="selected !== undefined ? `${selected}` : undefined"
  >
    <button
      v-for="(item, index) in items"
      :key="getID(item)"
      :class="[
        'flex w-full rounded-md px-2 py-1 min-h-16 items-center text-left',
        'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
        { 'text-gg-blue-900 bg-blue-300': selected === index },
        { 'bg-gray-100': selected !== index },
      ]"
      role="option"
      :aria-selected="selected === index"
      type="button"
      @click.prevent="() => select(item)"
      @mouseover="(event) => mouseover(event, index, item)"
      @focus="(event) => mouseover(event, index, item)"
    >
      <div>
        <div class="font-bold text-gray-80">
          {{ getTitle(item) }}
        </div>
        <div class="text-[#303741] text-xs">
          {{ getDescription(item) }}
        </div>
      </div>
    </button>
  </ul>
</template>

<script setup lang="ts">
import { props as searchProps } from './search-modal-results-interface';

const props = defineProps(searchProps);

const getID = (item: unknown): string | number => props.convert(item).id;
const getTitle = (item: unknown): string => props.convert(item).title;
const getDescription = (item: unknown): string | undefined => props.convert(item).description;
</script>

<style>
.min-h-16 {
  min-height: 4rem;
}
</style>
