export const getCsrfToken = () => {
  const csrfTokens = document.getElementsByName('csrf-token');
  const csrfToken = csrfTokens[0]?.getAttribute('content');
  return csrfToken ?? undefined;
};

export const setCsrfToken = (token: string) => {
  const csrfTokens = document.getElementsByName('csrf-token');
  csrfTokens[0].setAttribute('content', token);
};
