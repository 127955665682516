<template>
  <div v-if="title" class="text-gg-blue-700 text-xs font-semibold mb-2 text-center">
    {{ title }}
  </div>
  <label :class="labelClass">
    <input
      v-model="inputValue"
      :value="value"
      type="checkbox"
      :name="name"
      :disabled="disabled"
      class="peer w-0 h-0 opacity-0 absolute"
      :true-value="trueValue"
      :false-value="falseValue"
      :data-test-id="dataTestId"
    >
    <slot v-bind="{ modelValue, classes }" />
  </label>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue';
import { string, any, bool } from 'vue-types';

import {
  ColorType, focusPeerVisibleDefault, focusPeer, ColorRange,
} from '../../styling/colorProperties';
import { getTypeClass } from '../../Button/ButtonProperties';
import { SizeType, btnPaddings } from '@/ui/styling/sizeProperties';

export default defineComponent({
  props: {
    modelValue: any(),
    trueValue: any().def(true),
    falseValue: any().def(false),
    name: string(),
    title: string(),
    invert: bool(),
    disabled: bool(),
    class: any(),
    labelClass: any().def('relative'),
    type: string<ColorType>().def('primary'),
    color: string<ColorRange>().def('normal'),
    size: string<SizeType>().def('normal'),
    dataTestId: string(),
    value: any(),
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    const classes = computed(() => {
      const btnClasses = getTypeClass(props.type, props.color, props.disabled);
      const sized = btnPaddings[props.size];
      return ['rounded-lg', sized, focusPeerVisibleDefault, focusPeer[props.type], btnClasses];
    });

    return {
      classes,
      inputValue,
    };
  },
});
</script>
