import {
  useI18n as useI18nBase,
  createI18n,
  DefineNumberFormat,
  DefineDateTimeFormat,
} from 'vue-i18n';

import enUS from './texts/enUS';
import es from './texts/es';

type MessageSchema = typeof enUS;
const messages = { 'en-US': enUS, es };

const enNumberFormats: DefineNumberFormat = {
  currency: {
    style: 'currency',
    currencyDisplay: 'symbol',
    currency: 'USD',
  },
};

export const datetimeFormats = {
  short: {
    year: 'numeric', month: 'short', day: 'numeric',
  },
};
export type DatetimeFormatTypes = keyof typeof datetimeFormats;

const options = {
  legacy: false, // you must set `false`, to use Composition API
  locale: 'en-US',
  messages,
  numberFormats: { 'en-US': enNumberFormats },
  datetimeFormats: { 'en-US': datetimeFormats as DefineDateTimeFormat },
};

export const i18n = createI18n<[MessageSchema], 'en-US', false>(options);

const useI18n = () => useI18nBase<{
  message: MessageSchema,
  number: DefineNumberFormat,
  datetime: DefineDateTimeFormat
}, 'en-US'>(options);

export default useI18n;
