<template>
  <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
  <textarea
    ref="refInput"
    v-model="inputValue"
    :name="name"
    :class="[
      defaultClass,
      $props.class,
      { [errorClass]: errors.length > 0 },
      { [noErrorClass]: inputValue && errors.length === 0 },
    ]"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    @input="$emit('input')"
  />
</template>

<script lang="ts">
import {
  defineComponent, computed, ref, watch,
} from 'vue';
import { any, array, string } from 'vue-types';

export default defineComponent({
  name: 'TextArea',
  props: {
    name: string(),
    modelValue: any(),
    class: any(),
    errors: array().def([]),
    defaultClass: any().def('border text-left px-4 py-1 rounded w-full'),
    errorClass: any().def('border-red-500'),
    noErrorClass: any().def('border-gray-400'),
  },
  emits: [
    'update:modelValue',
    'ref',
    'focus',
    'blur',
    'input',
  ],
  setup(properties, { emit }) {
    const inputValue = computed({
      get: () => properties.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    const refInput = ref();
    watch(refInput, () => {
      emit('ref', refInput.value);
    });

    return {
      inputValue,
      refInput,
    };
  },
});
</script>
