import {
  useField,
  useForm as useVeeForm,
  FieldContext,
  FormOptions,
  RuleExpression,
} from 'vee-validate';
import { Ref, ref } from 'vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Any = any;
type MaybeRef<T = Any> = Ref<T> | T;

export interface RegisteredValueProps<TValue = unknown> extends FieldContext<TValue> {
  ref: Ref<HTMLElement>
  setRef: (ref: unknown) => void
}

const stringFromMaybeRef = (name: MaybeRef<string>) => (
  typeof name === 'string' ? name : name.value
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useForm = <FormValues extends Record<string, any>>(
  {
    initialValues,
    ...props
  }: FormOptions<FormValues>,
) => {
  const { handleSubmit, errors, ...forms } = useVeeForm<FormValues>({
    ...props,
    initialValues,
  });

  const internalRegistered: RegisteredValueProps[] = [];

  const register = <TValue = unknown>(
    name: MaybeRef<string>,
    rules?: MaybeRef<RuleExpression<TValue>> | Any,
    opts?: Any,
  ) => {
    const field = useField<TValue>(name, rules, opts);
    const refInput = ref();
    const result: RegisteredValueProps<TValue> = {
      ...field,
      ref: refInput,
      setRef: (refValue: unknown) => { refInput.value = refValue; },
    };
    internalRegistered.push(result);

    return result;
  };

  const focusError = () => {
    if (Object.keys(errors.value).length === 0) {
      return;
    }

    const refFields = internalRegistered.filter((reg) => reg.ref.value);

    refFields.sort((a, b) => {
      const comparison = a.ref.value.compareDocumentPosition(b.ref.value);
      // eslint-disable-next-line no-bitwise
      return comparison & 2 ? 1 : -1;
    });

    const fieldToFocus = refFields.find((reg) => {
      const name = stringFromMaybeRef(reg.name);
      return Object.keys(errors.value).includes(name);
    });

    if (fieldToFocus && fieldToFocus.ref.value) {
      setTimeout(() => {
        fieldToFocus.ref.value.focus();
      }, 100);
    }
  };

  return {
    ...forms,
    handleSubmit,
    errors,
    register,
    focusError,
  };
};

export default useForm;

export type RegisterType = ReturnType<typeof useForm>['register'];
