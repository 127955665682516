<template>
  <American v-if="isCC(cardType.AMERICAN)" />
  <Diners v-else-if="isCC(cardType.DINERS_CLUB)" />
  <Discover v-else-if="isCC(cardType.DISCOVER)" />
  <JCB v-else-if="isCC(cardType.JCB)" />
  <Maestro v-else-if="isCC(cardType.MAESTRO)" />
  <MasterCard v-else-if="isCC(cardType.MASTERCARD)" />
  <Visa v-else-if="isCC(cardType.VISA)" />
  <Generic v-else />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { string } from 'vue-types';
import { types as cardType } from 'credit-card-type';
import { CreditCardTypeCardBrandId } from 'credit-card-type/dist/types';

import American from './American.vue';
import Diners from './Diners.vue';
import Discover from './Discover.vue';
import JCB from './JCB.vue';
import Maestro from './Maestro.vue';
import MasterCard from './MasterCard.vue';
import Visa from './Visa.vue';
import Generic from './Generic.vue';

export default defineComponent({
  name: 'CreditCard',
  components: {
    American,
    Diners,
    Discover,
    JCB,
    Maestro,
    MasterCard,
    Visa,
    Generic,
  },
  props: {
    creditCardType: string<CreditCardTypeCardBrandId>().isRequired,
  },
  setup(props) {
    const isCC = (type: CreditCardTypeCardBrandId) => props.creditCardType === type;
    return {
      cardType,
      isCC,
    };
  },
});
</script>
