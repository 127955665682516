import Bugsnag from '@bugsnag/js';
import { App } from 'vue';
import BugsnagPerformance, { BrowserConfiguration } from '@bugsnag/browser-performance';

import getBugsnagConfig from '@/global/bugsnag/getBugsnagConfig';

const { bugsnagConfig, bugsnagClient } = getBugsnagConfig();

const browserConfiguration: BrowserConfiguration = bugsnagConfig;
BugsnagPerformance.start(browserConfiguration);

const bugsnag = Bugsnag
  .start(bugsnagClient)
  .getPlugin('vue');

const addBugsnag = (app: App<Element>) => {
  if (bugsnag) {
    app.use(bugsnag);
  }
};

export default addBugsnag;
