<template>
  <SearchModal
    v-model="inputValue"
    :placeholder="placeholder"
    :total-items="totalItems"
    :change="change"
    :loading="loading"
    :debounce="debounce"
    :error="error"
    :min-length-text="minLengthText"
    :on-select-by-index="onSelectByIndex"
    :input-id="inputId"
    :input-name="inputName"
    :input-aria-control="inputAriaControl"
    :label="label"
  >
    <template #button="{ openModal }">
      <slot v-bind="{ openModal, btnClass: btnClass() }">
        <button
          type="button"
          :class="btnClass()"
          :disabled="disabled"
          @click="openModal"
        >
          <slot name="btn_body" />
        </button>
      </slot>
    </template>
    <template #results="{ selected, onMouseOver, closeModal }">
      <slot
        name="results"
        v-bind="{
          selected,
          onMouseOver,
          closeModal,
        }"
      >
        <SearchModalResults
          :convert="convert"
          :select="(item) => onSelect(closeModal, item)"
          :items="items"
          :selected="selected"
          :mouseover="onMouseOver"
          :result-id="resultId"
        />
      </slot>
    </template>
  </SearchModal>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { object, string } from 'vue-types';

import inputProps from './inputProps';
import SearchModal from '@/ui/search-modal/search-modal.vue';
import propsSearchModel from '@/ui/search-modal/search-modal-interface';
import SearchModalResults from '@/ui/search-modal/search-modal-results.vue';
import { props as propsSearchModalResults } from '@/ui/search-modal/search-modal-results-interface';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { selected, mouseover, ...otherSearchModalResults } = propsSearchModalResults;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { modelValue, ...otherSearchModel } = propsSearchModel;
const {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  name, type, ...props
} = inputProps;

export default defineComponent({
  name: 'AutocompleteModal',

  components: {
    SearchModal,
    SearchModalResults,
  },

  props: {
    value: object(),
    ...otherSearchModel,
    ...otherSearchModalResults,
    ...props,
    inputId: string(),
    inputAriaControl: string(),
    inputName: string(),
    resultId: string(),
  },

  emits: ['update:modelValue'],

  setup(properties, { emit }) {
    const inputValue = computed({
      get: () => properties.modelValue,
      set: (val) => emit('update:modelValue', val),
    });
    return {
      inputValue,
    };
  },
  methods: {
    onSelect(closeModal: () => void, item: unknown) {
      closeModal();
      this.select(item);
    },
    btnClass() {
      const errorClass = (this.errors.length > 0)
        ? this.errorClass
        : this.noErrorClass;
      return `${this.class} ${this.defaultClass} ${errorClass}`;
    },
  },
});
</script>
