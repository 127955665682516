<template>
  <button
    :class="[
      baseClass,
      $props.class,
    ]"
    :disabled="disabled || loading"
    :type="htmlType"
    :dataTestId="dataTestId"
    @click="$emit('click', $event)"
  >
    <i v-if="loading" class="fas fa-spinner fa-spin mr-1" />
    {{ text }}
    <slot />
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { buttonProps, getLinkClasses } from './ButtonProperties';

const props = defineProps(buttonProps);

const baseClass = computed(() => getLinkClasses(
  props.type,
  props.disabled || props.loading,
));

defineEmits<
{(event: 'click', payload: MouseEvent): void
  (event: 'keydown', payload: KeyboardEvent): void
}>();
</script>
