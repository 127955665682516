import {
  object, func, number, string,
} from 'vue-types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Item = any;
export interface Result {
  id: string | number;
  title: string;
  description?: string;
}

export const props = {
  convert: func<(item: Item) => Result>().isRequired,
  select: func<(item: Item) => void>().isRequired,
  items: object<Item[]>().def([]),
  selected: number(),
  mouseover: func<(event: MouseEvent | FocusEvent, index: number, item: Item) => void>().isRequired,
  resultId: string(),
};
