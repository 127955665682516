export enum ErrorStatusEnum {
  error='error',
  failed='failed',
  http='http',
  canceled='canceled',
  critical='critical',
}

export enum ErrorCodeEnum {
  authentication='AuthenticationException',
  critical='CRITICAL_EXCEPTION',
  message='MessageException',
  validation='ValidationException',
  validation2='VALIDATION',
  billing='BILLING',
  token='TokenMismatchException',
}

export interface ValidationErrors {
  [key: string]: string[];
}
export interface ValidationErrorResponse {
  status: ErrorStatusEnum.error,
  error: {
    error_code: ErrorCodeEnum.validation | ErrorCodeEnum.validation2,
    error_message: string,
    errors: ValidationErrors,
  };
}

export interface ErrorResponse {
  status: ErrorStatusEnum,
  error: {
    error_code: (
      ErrorCodeEnum.critical |
      ErrorCodeEnum.message |
      ErrorCodeEnum.billing |
      ErrorCodeEnum.token
    ),
    error_message: string,
  };
}

export interface HttpErrorResponse {
  status: 'httpStatusError',
  error: {
    error_code: ErrorStatusEnum.http,
    error_message: string,
    httpStatus: number,
  };
}

export type ApiResponseErrors = (
  ErrorResponse |
  ValidationErrorResponse |
  HttpErrorResponse
)
