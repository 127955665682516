import { onUnmounted, reactive, ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { useGet, ApiResponse } from 'use-api-vue';

import { ServerErrors } from '@/plugin/ajax/ApiResponse';
import InsuredAddressProperties from '@/interfaces/insuredAddressProperties';

export interface SchoolProperties extends InsuredAddressProperties {
  id: number;
  school_name: string;
  school_short_name?: string;
  tracking_code?: string;
  logo_url?: string;
  show_renters?: 1 | 0,
  show_tuition?: 1 | 0,
  school_schedule?: 'semester' | 'trimester' | 'quarter' | 'other',
}

interface GetSchoolParams {
  term: string;
  only_tuition?: boolean;
  only_renters?: boolean;
}

type ServerResponse = { data: SchoolProperties[] };

const useSchools = (isOnlyTuition?: boolean, isOnlyRenters?: boolean) => {
  const term = ref('');
  const onlyTuition = ref(isOnlyTuition);
  const onlyRenters = ref(isOnlyRenters);

  const get = useGet({ onCancelCallback: onUnmounted });
  const {
    isFetching, data, error, refetch,
  } = useQuery<ApiResponse<ServerResponse>, ServerErrors>({
    queryKey: reactive(['/api/schools', { term, onlyTuition, onlyRenters }]),
    queryFn: (context) => {
      const input = context.queryKey[1] as GetSchoolParams;
      return get<ServerResponse, GetSchoolParams>(
        '/api/schools',
        input,
      );
    },
    enabled: false,
  });

  const onChange = () => {
    // cancelRefetch?: boolean
    // When set to true, then the current request will be cancelled before a new request is made
    // https://react-query.tanstack.com/reference/QueryClient#queryclientinvalidatequeries
    if (term.value.length > 2) {
      refetch({ cancelRefetch: true });
    }
  };

  return {
    inputValue: term,
    onlyTuition,
    onlyRenters,
    isFetching,
    data,
    error,
    refetch,
    onChange,
  };
};

export default useSchools;
