<template>
  <Combobox v-model="inputValue">
    <ComboboxInput
      :display-value="displayvalue"
      :name="name"
      :class="$props.class"
      :required="required"
      autocomplete="off"
      @change="query = $event.target.value"
    />
    <ComboboxButton
      class="absolute inset-y-0 right-0 flex items-center pr-2"
    >
      <ChevronUpDownIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
    </ComboboxButton>
    <div class="relative mt-1 z-20">
      <TransitionRoot
        leave="transition ease-in duration-100"
        leave-from="opacity-100"
        leave-to="opacity-0"
        @after-leave="query = ''"
      >
        <ComboboxOptions
          :class="[
            'absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md',
            'shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
          ]"
        >
          <div
            v-if="filteredValues.length === 0 && query !== ''"
            class="cursor-default select-none relative py-2 px-4 text-gray-700"
          >
            Nothing found.
          </div>

          <ComboboxOption
            v-for="value in filteredValues"
            :key="value.value"
            v-slot="{ selected: isSelected, active }"
            as="template"
            :value="value"
          >
            <li
              class="cursor-default select-none relative py-2 px-4 flex"
              :class="{
                'text-white bg-gg-blue-600': active,
                'text-gray-900': !active,
              }"
            >
              <slot :name="value.value" v-bind="{ value, isSelected }">
                <div
                  class="block truncate flex-1"
                  :class="{ 'font-medium': isSelected, 'font-normal': !isSelected }"
                >
                  {{ value.label }}
                </div>
              </slot>
              <span
                v-if="isSelected"
                class="flex items-center pl-3"
                :class="{ 'text-white': active, 'text-teal-600': !active }"
              >
                <CheckIcon class="w-5 h-5" aria-hidden="true" />
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
  </Combobox>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/24/solid';
import {
  any, array, bool, string,
} from 'vue-types';

import AutocompleteValue from './AutocompleteValue';

export default defineComponent({
  name: 'Autocomplete',
  components: {
    Combobox,
    ComboboxInput,
    ComboboxButton,
    ComboboxOptions,
    ComboboxOption,
    TransitionRoot,
    CheckIcon,
    ChevronUpDownIcon,
  },
  props: {
    modelValue: any(),
    options: array<AutocompleteValue>().isRequired,
    name: string(),
    class: any(),
    required: bool(),
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    const query = ref('');
    const filteredValues = computed(() => {
      if (!query.value) {
        return props.options;
      }
      return props.options.filter((option) => option.label
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(query.value.toLowerCase().replace(/\s+/g, '')));
    });

    return {
      query,
      inputValue,
      filteredValues,
      displayvalue: (value: unknown) => (value as AutocompleteValue)?.label,
    };
  },
});
</script>
