export type SizeType = 'custom' | 'large' | 'big' | 'normal' | 'medium' | 'small' | 'xs';

export const btnPaddings: Record<SizeType, string> = {
  custom: '',
  large: 'px-16 py-4 text-md',
  big: 'px-10 py-4 text-md',
  normal: 'px-6 py-3 text-md',
  medium: 'px-5 py-2 text-sm',
  small: 'px-3 py-1 text-xs',
  xs: 'px-1 py-0.5 text-xs',
};
