<template>
  <TransitionRoot appear :show="show" as="template">
    <Dialog
      as="div"
      class="relative z-50"
      :initial-focus="null"
      @close="onInternalClose"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto" :data-test-id="dataTestId">
        <div :class="containerClass">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              :class="[
                $props.class,
                'transform transition-all',
                {
                  'overflow-hidden bg-white p-6 shadow-xl rounded-2xl max-w-full': !deleteClass,
                },
              ]"
            >
              <CloseButton v-if="(showCloseButton && !!close)" :close="onInternalClose" />
              <DialogTitle
                v-if="title"
                :id="titleId"
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900 sr-only"
              >
                {{ title }}
              </DialogTitle>
              <slot v-bind="{ titleId }" />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
import {
  defineComponent, ref, watch, onMounted, nextTick,
} from 'vue';
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogTitle,
  DialogPanel,
} from '@headlessui/vue';

import props from './modalProps';
import CloseButton from './CloseButton.vue';
import useId from '@/ui/modal/useId';

export default defineComponent({
  name: 'Modal',
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogTitle,
    DialogPanel,
    CloseButton,
  },
  props,
  setup(properties) {
    // ADA: Focus must return to element that activates modal
    const lastFocusedElement = ref<HTMLElement | null>(null);

    const saveFocus = () => {
      lastFocusedElement.value = document.activeElement as HTMLElement | null;
    };

    const restoreFocus = () => {
      if (lastFocusedElement.value?.focus && document.contains(lastFocusedElement.value)) {
        nextTick(() => {
          lastFocusedElement.value?.focus();
        });
      }
    };

    onMounted(() => {
      if (properties.show) saveFocus();
    });

    watch(
      () => properties.show,
      (newShow) => {
        // eslint-disable-next-line no-unused-expressions
        newShow ? saveFocus() : restoreFocus();
      },
    );

    const onInternalClose = () => {
      if (properties.close) {
        properties.close();
      }
      restoreFocus();
    };

    const titleId = useId();

    return {
      onInternalClose,
      titleId,
    };
  },
});
</script>
