<script setup lang="ts">
import { computed } from 'vue';

import { TotalPurchasedBaseDTO, TotalPurchasedStatus } from './useTotalPoliciesLast24hrs';
import config from '@/global/constants';

const props = defineProps<{ data?: TotalPurchasedBaseDTO }>();

const minNoParter = (config.is.qa || config.is.local) ? 10 : 100;
const minParter = (config.is.qa || config.is.local) ? 5 : 50;

const getTotal = (): TotalPurchasedStatus => {
  if (!props.data) {
    return { total: 0, status: 'hide' };
  }
  if (props.data.isPartner) {
    if (props.data.school.total >= minParter) {
      return { total: props.data.school.total, status: 'school' };
    }
    return (props.data.all.total >= minNoParter)
      ? { total: props.data.all.total, status: 'all' }
      : { total: props.data.all.total, status: 'hide' };
  }
  return (props.data.all.total >= minNoParter)
    ? { total: props.data.all.total, status: 'all' }
    : { total: props.data.all.total, status: 'hide' };
};

const total = computed(() => getTotal());
</script>

<template>
  <slot v-if="total.status !== 'hide'" v-bind="{ data, total }" />
</template>
