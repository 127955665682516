import { Ref, computed } from 'vue';

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
let _id = 0;

// eslint-disable-next-line no-plusplus
const uid = () => `nuid-modal-${_id++}`;

const useId = (id?: Ref<string | undefined>) => {
  const uId = uid();
  return computed(() => id?.value ?? uId);
};

export default useId;
