<template>
  <slot />
</template>

<script lang="ts">

import { defineComponent } from 'vue';
import { useApiProvider } from 'use-api-vue';

import { getError } from '@/plugin/ajax/ApiResponse';
import constants from '@/global/constants';
import { getCsrfToken } from '@/global/CsrfToken';

export default defineComponent({
  name: 'Wrapper',
  setup() {
    // Call the same server Gradguard.com
    useApiProvider({
      requestConfig: { withCredentials: true },
      extraPostData: () => ({ _token: getCsrfToken() }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getError: (error: any) => getError(error),
    });

    // Call API-V2 enpoint
    useApiProvider({
      requestConfig: {
        headers: {
          Authorization: `Bearer ${constants.tokens.apiV2}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        baseURL: `${constants.domains.api}/v2`,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getError: (error: any) => getError(error as any),
    }, 'api-v2');
  },
});
</script>
