import {
  string, object, func, number, bool,
} from 'vue-types';

const props = {
  label: string(),
  placeholder: string(),
  change: func<() => void>(),
  modelValue: string().def(''),
  debounce: number().def(300),
  class: string().def('w-screen-md'),
  error: object(),
  totalItems: number().def(0),
  loading: bool(),
  minLengthText: number().def(0),
  onSelectByIndex: func<(index: number) => void>(),
};

export default props;
