import { App } from 'vue';
import { VueQueryPlugin, VueQueryPluginOptions } from '@tanstack/vue-query';

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnReconnect: false,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: false,
        refetchInterval: 0,
      },
    },
  },
};

const addVueQuery = (app: App<Element>) => {
  app.use(VueQueryPlugin, vueQueryPluginOptions);
};

export default addVueQuery;
