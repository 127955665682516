export interface ClassesProperties {
  container: string;
  label: string;
  element: string;
}

export const layoutValues = ['vertical', 'horizontal'];
export type typeLayout = typeof layoutValues[number]

const horizontal = {
  container: 'md:flex items-center gap-6 mb-6',
  label: 'md:w-1/4 md:text-right mb-2 md:mb-0',
  element: 'md:w-3/4',
};

const vertical = {
  container: 'mb-6',
  label: 'mb-2',
  element: '',
};

export const getStyles = (type: typeLayout, classes?: ClassesProperties): ClassesProperties => {
  if (classes) {
    return classes;
  }
  switch (type) {
    case 'horizontal': return horizontal;
    case 'vertical': return vertical;
    default: throw new Error(`You need to add the new type: ${type}`);
  }
};
