export type AlertType = (
  'primary' |
  'success' |
  'secundary' |
  'warning' |
  'error' |
  'custom'
);

export const getBaseClass = (type: AlertType, invert?: boolean) => {
  if (invert) {
    switch (type) {
      case 'primary': return 'border border-blue-200 text-blue-800';
      case 'success': return 'border border-green-200 text-green-800';
      case 'secundary': return 'border border-gray-300 text-gray-600';
      case 'warning': return 'border bg-yellow-200 text-yellow-900';
      case 'error': return 'border border-red-200 text-red-700';
      case 'custom': return '';
      default: throw new Error(`The current ${type} is not defined`);
    }
  } else {
    switch (type) {
      case 'primary': return 'bg-blue-200 text-blue-800';
      case 'success': return 'bg-green-200 text-green-800';
      case 'secundary': return 'bg-gray-300 text-gray-600';
      case 'warning': return 'bg-yellow-200 text-yellow-900';
      case 'error': return 'bg-red-200 text-red-700';
      case 'custom': return '';
      default: throw new Error(`The current ${type} is not defined`);
    }
  }
};

export const getSizeClass = (size?: string) => (size === 'small'
  ? 'text-sm px-2 py-0.5 inline-block rounded-lg'
  : 'py-2 px-3 rounded-xl');

export const getBaseIcon = (type: AlertType) => {
  switch (type) {
    case 'primary': return 'fas fa-info';
    case 'success': return 'fas fa-check-circle';
    case 'warning': return 'fas fa-exclamation-triangle';
    case 'error': return 'fas fa-exclamation-circle';
    default: return undefined;
  }
};

export const getClass = (type: AlertType, invert?: boolean, size?: string) => {
  const base = getBaseClass(type, invert);
  const sizeClass = getSizeClass(size);
  return `${base} ${sizeClass}`;
};
