<template>
  <label :class="labelClass">
    <input
      v-model="inputValue"
      :name="name"
      :class="[
        $props.class,
        { ' cursor-not-allowed': disabled },
      ]"
      :disabled="disabled"
      type="checkbox"
      :data-test-id="dataTestId"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @input="$emit('input')"
    >
    <slot>
      <div v-if="label" class="ml-5">
        {{ label }}
      </div>
    </slot>
  </label>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { any, string } from 'vue-types';

export default defineComponent({
  name: 'Checkbox',
  props: {
    name: string(),
    modelValue: any(),
    class: any().def('w-4 h-4'),
    labelClass: string().def('flex items-center'),
    label: string(),
    disabled: Boolean,
    dataTestId: string(),
  },
  emits: [
    'update:modelValue',
    'focus',
    'blur',
    'input',
  ],
  setup(properties, { emit }) {
    const inputValue = computed({
      get: () => properties.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    return {
      inputValue,
    };
  },
});
</script>
