<template>
  <!-- eslint-disable max-len -->
  <SwitchC
    v-model="inputValue"
    :class="[
      'relative inline-flex h-[38px] w-[74px] ',
      'shrink-0 rounded-full border-2',
      'transition-colors duration-200 ease-in-out',
      'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
      switchClass,
      { 'cursor-not-allowed opacity-70': disabled },
      { 'cursor-pointer': !disabled },
    ]"
  >
    <span class="sr-only">{{ switchName ?? 'Toggle switch' }}</span>
    <span
      aria-hidden="true"
      :class="inputValue ? 'translate-x-9' : 'translate-x-0'"
      class="pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out relative z-10"
    />
    <div
      :class="[
        'flex items-center justify-between absolute z-0 w-full',
        'overflow-hidden px-2.5 h-[34px] rounded-full',
      ]"
    >
      <svg
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 6.2L5.4 10.6L13 1"
          stroke="white"
          stroke-width="1.83333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.00017 10.9433L10.8869 1.05656"
          stroke="white"
          stroke-width="1.83333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.9999 11L1 1"
          stroke="white"
          stroke-width="1.83333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </SwitchC>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { any, string } from 'vue-types';
import { Switch as SwitchC } from '@headlessui/vue';

import { ColorType } from '../styling/colorProperties';

export const getTypeClass = (type: ColorType, inputValue?: boolean) => {
  if (!inputValue) {
    return 'bg-gray-400 border-gray-400 focus-visible:ring-gray-400';
  }
  switch (type) {
    case 'custom': return '';
    case 'primary': return 'bg-gg-blue-600 border-gg-blue-600 focus-visible:ring-gg-blue-600';
    case 'success': return 'bg-positive border-positive focus-visible:ring-positive';
    case 'secondary': return 'bg-gray-400 border-gray-400 focus-visible:ring-gray-400';
    case 'error': return 'bg-negative border-negative focus-visible:ring-negative';
    default: throw new Error(`The current ${type} is not defined`);
  }
};

export default defineComponent({
  name: 'Switch',
  components: {
    SwitchC,
  },
  props: {
    name: string(),
    modelValue: any(),
    class: any(),
    label: string(),
    type: string<ColorType>().def('primary'),
    disabled: Boolean,
    switchName: string(),
  },
  emits: [
    'update:modelValue',
    'focus',
    'blur',
    'input',
  ],
  setup(properties, { emit }) {
    const inputValue = computed({
      get: () => properties.modelValue,
      set: (value) => {
        if (!properties.disabled) {
          emit('update:modelValue', value);
        }
      },
    });

    const switchClass = computed(() => getTypeClass(properties.type, inputValue.value));

    return {
      inputValue,
      switchClass,
    };
  },
});
</script>
