<script setup lang="ts">

import { usePost } from 'use-api-vue';
import { onUnmounted, ref } from 'vue';
import { useMutation } from '@tanstack/vue-query';

const props = defineProps({
  class: String,
  classItem: String,
});

const display = ref(true);

const post = usePost({ onCancelCallback: onUnmounted });

const saveCookie = useMutation({ mutationFn: () => post('/accept_gdpr') });

const accept = () => {
  display.value = false;
  saveCookie.mutate();
};
</script>

<template>
  <div
    :class="[
      'px-2.5 min-[450px]:px-5 py-px bg-gg-blue-800 text-white rounded text-sm max-w-fit',
      'text-left xs:text-center',
      'whitespace-nowrap',
      { hidden: !display },
      { 'inline-block': display },
      props.class,
    ]"
    role="dialog"
    aria-label="Cookie Acknowledgement"
  >
    <span>We use cookies&nbsp;</span>
    <span role="img" aria-hidden="true">🍪</span>
    &dash;
    <a
      href="/cookies-policy"
      class="text-white mr-2 min-[390px]:mr-4 my-2 inline-block underline hover:no-underline"
      aria-label="Link to GradGuard cookies policy"
    >
      learn more
    </a>
    <button
      class="rounded mx-auto my-2 text-gg-blue-800 bg-white btn-white w-14"
      type="button"
      aria-label="Click to acknowledge GradGuard cookies policy"
      @click="accept"
    >
      Got it
    </button>
  </div>
</template>
