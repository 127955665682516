<template>
  <div
    :class="[baseClass(), $props.class]"
    role="alert"
  >
    <i v-if="!hideIcon && baseIcon()" :class="[baseIcon(), 'mr-1']" />
    <span v-if="message">
      {{ message }}
    </span>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { string } from 'vue-types';

import { getClass, getBaseIcon, AlertType } from './alertFunctions';

export default defineComponent({
  name: 'SmallAlert',
  props: {
    type: string<AlertType>().def('primary'),
    size: string<'small'>(),
    icon: string(),
    hideIcon: Boolean,
    invert: Boolean,
    message: string(),
    class: string(),
  },
  methods: {
    baseClass() {
      return getClass(this.$props.type, this.$props.invert, this.$props.size);
    },
    baseIcon() {
      return this.$props.icon ?? getBaseIcon(this.$props.type);
    },
  },
});
</script>
