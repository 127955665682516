// eslint-disable-next-line max-classes-per-file
import { ApiResponseErrors, ErrorStatusEnum, ErrorCodeEnum } from './ApiResponseErrorInterfaces';

export interface ValidationErrors {
  [key: string]: string[];
}

/**
 * To format the errors from the connection or server.
 * It's like 401, 500, Network Error, etc.
 * it's should be extended from `AxiosResponse<T>`
 * @param error
 */
type APIErrorResponse<T> = {
  code?: 'ERR_CANCELED',
  response?: {
    data: T;
    /** 500, 404, etc. */
    status: number;
  }
  message: string;
  statusText: string;
} & Error;

export class OriginalError extends Error {
  constructor(
    public readonly message: string,
    public readonly code: string,
    public readonly original?: APIErrorResponse<ApiResponseErrors> | APIErrorResponse<never>,
  ) {
    super();
  }
}

export class ValidationError extends OriginalError {
  constructor(
    public readonly message: string,
    public readonly code: string,
    public readonly errors: ValidationErrors,
    public readonly original?: APIErrorResponse<ApiResponseErrors> | APIErrorResponse<never>,
  ) {
    super(message, code, original);
    Object.setPrototypeOf(this, ValidationError.prototype);
  }
}

export class MessageError extends OriginalError {
  constructor(
    public readonly message: string,
    public readonly code: string,
    public readonly original?: APIErrorResponse<ApiResponseErrors> | APIErrorResponse<never>,
  ) {
    super(message, code, original);
    Object.setPrototypeOf(this, MessageError.prototype);
  }
}

export class TokenError extends OriginalError {
  constructor(
    public readonly message: string,
    public readonly code: string,
    public readonly original?: APIErrorResponse<ApiResponseErrors> | APIErrorResponse<never>,
  ) {
    super(message, code, original);
    Object.setPrototypeOf(this, TokenError.prototype);
  }
}

export class ErrorResponse extends OriginalError {
  constructor(
    public readonly message: string,
    public readonly code: string,
    public readonly original?: APIErrorResponse<ApiResponseErrors> | APIErrorResponse<never>,
  ) {
    super(message, code, original);
    Object.setPrototypeOf(this, ErrorResponse.prototype);
  }
}

export type ServerErrors = ValidationError | ErrorResponse | null;

const getErrorCode = (
  error: APIErrorResponse<ApiResponseErrors>,
): ErrorStatusEnum | string => {
  if (error.code === 'ERR_CANCELED' || error.message === 'canceled') {
    return ErrorStatusEnum.canceled;
  }

  if (error?.response?.status === 500) {
    return ErrorStatusEnum.critical;
  }
  if (error.response?.data?.error?.error_code) {
    return error.response.data.error.error_code;
  }

  return ErrorStatusEnum.http;
};

export const getError = (
  error: APIErrorResponse<ApiResponseErrors> | APIErrorResponse<never>,
): ServerErrors => {
  const axiosResponse = error?.response;
  if (
    axiosResponse
    && axiosResponse.data
    && typeof axiosResponse.data === 'object'
    && 'error' in axiosResponse.data
    && axiosResponse.data.error
  ) {
    const errors = axiosResponse.data.error;
    if (
      errors.error_code === ErrorCodeEnum.validation
      || errors.error_code === ErrorCodeEnum.validation2
    ) {
      return new ValidationError(
        errors.error_message,
        errors.error_code,
        errors.errors,
      );
    }
    if (errors.error_code === ErrorCodeEnum.message
        || errors.error_code === ErrorCodeEnum.billing
    ) {
      return new MessageError(errors.error_message, getErrorCode(error));
    }
    if (errors.error_code === ErrorCodeEnum.token) {
      return new TokenError(errors.error_message, getErrorCode(error));
    }
    return new ErrorResponse(errors.error_message, getErrorCode(error), error);
  }
  return new ErrorResponse(
    'The application has encountered an unknown error. Please call (866) 985-7598 for assistance.',
    getErrorCode(error),
    error,
  );
};

export interface ServerResponse<Response = unknown> {
  data: Response;
}
