<template>
  <slot name="button" v-bind="{ openModal }" />
  <modal
    :show="show"
    :close="closeModal"
    :class="$props.class"
    container-class="flex min-h-full justify-center"
    :close-modal="closeModal"
    :delete-class="true"
  >
    <SearchModalContent
      v-model="inputValue"
      :close-modal="closeModal"
      :placeholder="placeholder"
      :change="change"
      :debounce="debounce"
      :class="$props.class"
      :error="error"
      :total-items="totalItems"
      :loading="loading"
      :min-length-text="minLengthText"
      :input-id="inputId"
      :input-name="inputName"
      :input-aria-control="inputAriaControl"
      :on-select-by-index="onInternalSelectByIndex"
      :result-id="resultId"
      :label="label"
    >
      <template #results="{ selected, onMouseOver }">
        <slot name="results" v-bind="{ selected, onMouseOver, closeModal }" />
      </template>
    </SearchModalContent>
  </modal>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { string } from 'vue-types';

import modal from '../modal/modal.vue';
import SearchModalContent from './search-modal-content.vue';
import searchModalProps from './search-modal-interface';

export default defineComponent({
  components: {
    modal,
    SearchModalContent,
  },

  props: {
    ...searchModalProps,
    inputId: string(),
    inputAriaControl: string(),
    inputName: string(),
    resultId: string(),
  },
  emits: ['update:modelValue'],

  setup(properties, { emit }) {
    const inputValue = computed({
      get: () => properties.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    const show = ref(false);
    const triggerElement = ref<HTMLElement|null>(null);
    return {
      show,
      inputValue,
      triggerElement,
    };
  },

  methods: {
    closeModal() {
      this.show = false;

      // ADA: Restore focus to the triggering element
      setTimeout(() => {
        if (this.triggerElement) {
          this.triggerElement.focus();
        }
      }, 250); // ADA: Delay to ensure DOM updates are complete
    },
    openModal() {
      // ADA: Save the element that triggered the modal before opening it
      this.triggerElement = document.activeElement as HTMLElement;
      this.show = true;
    },
    onInternalSelectByIndex(index: number) {
      if (this.onSelectByIndex) {
        this.closeModal();
        this.onSelectByIndex(index);
      }
    },
  },
});
</script>
