<template>
  <button
    :class="[
      baseClass,
      $props.class,
      defaultClass,
      { 'opacity-50 cursor-not-allowed': loading || disabled },
    ]"
    :type="htmlType"
    :disabled="loading || disabled"
    :tabindex="tabindex"
    :data-test-id="dataTestId"
    @click="$emit('click', $event)"
    @keydown="$emit('keydown', $event)"
  >
    <template v-if="loading">
      <i :class="[iconLoading, 'mr-2']" />
      <span v-if="textLoading">
        {{ textLoading }}
      </span>
      <span v-else>
        {{ text }}
      </span>
    </template>
    <template v-else>
      <i v-if="icon" :class="[icon, 'mr-2']" />
      <span v-if="text">
        {{ text }}
      </span>
    </template>
    <slot />
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { buttonProps, getClasses } from './ButtonProperties';

const props = defineProps(buttonProps);

const baseClass = computed(() => getClasses(
  props.type,
  props.size,
  props.defaultClass,
  props.color,
  props.disabled,
));

defineEmits<
{(event: 'click', payload: MouseEvent): void
  (event: 'keydown', payload: KeyboardEvent): void
}>();
</script>
