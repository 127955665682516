import countries from '@/plugin/data/countries';

const getCountry = (value?: string) => {
  if (!value) {
    return undefined;
  }
  return countries.find((country) => country.value === value);
};

export default getCountry;
