<template>
  <AutocompleteModal
    v-model="query"
    :value="countrySelected"
    placeholder="Start typing to search a country"
    label="Country Search"
    :total-items="filteredValues.length"
    :min-length-text="3"
    :convert="convert"
    :select="select"
    :on-select-by-index="onSelectByIndex"
    :items="filteredValues"
    input-id="country_search"
    input-aria-control="country-search-results"
    input-name="country_search"
  >
    <template #default="{ openModal }">
      <button
        type="button"
        :title="countrySelected?.name"
        :class="[
          $props.class,
          { 'cursor-not-allowed': disabled },
        ]"
        :disabled="disabled"
        aria-label="Country"
        @click="openModal"
      >
        <div class="flex items-center" aria-labelledby="callingCode">
          <div v-if="countrySelected" class="flex space-x-1 items-center">
            <span :class="`fi fi-${countrySelected.alpha2.toLowerCase()} rounded`" />
            <span id="callingCode" :aria-label="`${countrySelected.calling_code}`">
              +{{ countrySelected.calling_code }}
            </span>
          </div>
          <span>&nbsp;</span>
          <ChevronDownIcon class="w-5 h-5 text-gray-600" aria-hidden="true" />
        </div>
      </button>
    </template>
    <template
      #results="{ selected, onMouseOver, closeModal }"
    >
      <div aria-live="assertive" role="status">
        <div role="listbox">
          <ul
            id="country-search-results"
            tabindex="0"
            class="list-none p-0 m-0"
            @keydown="handleArrowNavigation"
          >
            <li>
              <button
                v-for="(currentCountry, index) in filteredValues"
                :key="currentCountry.id"
                :disabled="disabled"
                :class="[
                  'border-b flex py-2 items-center space-x-4 w-full rounded px-3',
                  { 'text-primary bg-blue-300': selected === index },
                  { 'cursor-not-allowed': disabled },
                ]"
                role="option"
                :aria-selected="selected === index"
                type="button"
                @click.prevent="() => onSelect(closeModal, currentCountry)"
                @mouseover="(event) => onMouseOver(event, index, currentCountry)"
                @keydown="handleArrowNavigation"
                @focus="(event) => onMouseOver(event, index, currentCountry)"
              >
                <span :class="`fi fi-${currentCountry.alpha2.toLowerCase()} rounded`" />
                <span role="status">
                  {{ currentCountry.name }}
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </AutocompleteModal>
</template>

<script lang="ts">
import {
  defineComponent, ref, computed, watch,
} from 'vue';
import { any, object } from 'vue-types';
import { ChevronDownIcon } from '@heroicons/vue/24/solid';

import AutocompleteModal from '../AutocompleteModal.vue';
import { Result } from '@/ui/search-modal/search-modal-results-interface';
import { Country, serverData } from '@/global/serverData';

const { countries } = serverData;

const getCountry = (value?: number) => {
  if (!value) {
    return undefined;
  }
  return countries.find((country) => country.id === value);
};

export default defineComponent({
  name: 'AutocompleteModalCountry',
  components: {
    AutocompleteModal,
    ChevronDownIcon,
  },

  props: {
    modelValue: any(),
    country: object<Country>(),
    class: any(),
    disabled: Boolean,
  },

  emits: ['submit', 'update:modelValue', 'update:country'],

  setup(properties, { emit }) {
    const countrySelected = ref<Country | undefined>(
      getCountry(properties.modelValue),
    );
    emit('update:country', countrySelected.value);

    watch(() => properties.country, () => {
      countrySelected.value = properties.country;
    });

    const query = ref<string>('');
    const filteredValues = computed(() => {
      if (!query.value) {
        return countries;
      }
      return countries.filter((option) => option.name
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(query.value.toLowerCase().replace(/\s+/g, '')));
    });

    return {
      query,
      countrySelected,
      countries,
      filteredValues,
    };
  },

  data() {
    return {
      selectedItemIndex: -1,
    };
  },

  methods: {
    convert(country: Country): Result {
      return {
        id: country.id,
        title: country.name,
      };
    },
    onSelect(fn: () => void, country: Country) {
      fn();
      this.select(country);
    },
    select(country: Country) {
      this.countrySelected = country;
      this.$emit('update:modelValue', country.id);
      this.$emit('update:country', country);
    },
    onSelectByIndex(index: number) {
      const country = this.filteredValues[index];
      if (country) {
        this.countrySelected = country;
        this.$emit('update:modelValue', country.id);
        this.$emit('update:country', country);
      }
    },
    handleArrowNavigation(event: KeyboardEvent) {
      if (event.key === 'ArrowDown') {
        // Handle down arrow key press
        event.preventDefault();
        const currentIndex = this.filteredValues.findIndex(
          (country) => country.id === this.selectedItemIndex,
        );
        if (currentIndex < this.filteredValues.length - 1) {
          this.selectedItemIndex = this.filteredValues[currentIndex + 1].id;
        }
      } else if (event.key === 'ArrowUp') {
        // Handle up arrow key press
        event.preventDefault();
        const currentIndex = this.filteredValues.findIndex(
          (country) => country.id === this.selectedItemIndex,
        );
        if (currentIndex > 0) {
          this.selectedItemIndex = this.filteredValues[currentIndex - 1].id;
        }
      }
    },
  },
});
</script>
