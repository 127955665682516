<template>
  <Autocomplete
    v-model="inputValue"
    :options="countriesValues"
    :name="name"
    :class="$props.class"
    :required="required"
  >
    <template
      v-for="(countriesValue, index) in countriesValues"
      #[countriesValue.value]="{ isSelected }"
      :key="countriesValue.value"
    >
      <div
        class="block truncate flex-1"
        :class="{ 'font-medium': isSelected, 'font-normal': !isSelected }"
      >
        <span :class="`fi fi-${countries[index].alpha2.toLowerCase()}`" />
        {{ countriesValue.label }}
      </div>
    </template>
  </Autocomplete>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { any, bool, string } from 'vue-types';

import Autocomplete from './Autocomplete.vue';
import AutocompleteValue from './AutocompleteValue';
import getCountry from './getCountry';
import { rentersServerData as serverData } from '@/global/serverData';

const { countries } = serverData;

export default defineComponent({
  name: 'AutocompleteCountry',
  components: {
    Autocomplete,
  },
  props: {
    modelValue: string(),
    valueType: string<'alpha2' | 'id'>().def('alpha2'),
    name: string(),
    class: any(),
    required: bool(),
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const countriesValues: AutocompleteValue[] = countries.map((country) => ({
      label: country.name,
      value: props.valueType === 'id' ? country.id : country.alpha2,
    }));
    const inputValue = ref<AutocompleteValue | undefined>(
      getCountry(props.modelValue),
    );
    watch(inputValue, () => {
      emit('update:modelValue', inputValue.value?.value);
    });
    watch(() => props.modelValue, () => { inputValue.value = getCountry(props.modelValue); });
    return {
      countriesValues,
      countries,
      inputValue,
    };
  },
});
</script>
