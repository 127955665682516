<template>
  <div class="mt-10 mx-2">
    <div class="bg-white shadow-xl rounded-2xl p-6 border-2 border-gg-blue-600 my-8" role="dialog" aria-label="Search modal">
      <SearchModalInput
        ref="searchInput"
        v-model="inputValue"
        :placeholder="placeholder"
        :expanded="totalItems > 0"
        :input-id="inputId"
        :input-name="inputName"
        :input-aria-control="inputAriaControl"
        :close="closeModal"
        :keydown="keyEvent"
        :label="label"
        :aria-live-status="loading ? 'assertive' : 'off'"
      />

      <div v-if="error">
        <div class="text-left">
          {{ error.message }}
        </div>
      </div>

      <div v-if="loading || totalItems > 0" class="border-b mb-4 pb-4" />

      <!-- Status message for search results -->
      <div class="text-left font-bold text-gray-600" role="status" aria-live="polite">
        <div v-if="loading" class="mb-4" aria-live="polite">
          <i class="fas fa-spinner animate-spin" /> Loading results...
        </div>
        <div v-else-if="totalItems > 0" class="mb-4" aria-live="polite">
          <span class="sr-only">Search Complete.</span>
          {{ totalItems }} Results
          <span class="sr-only"> Found</span>
        </div>
        <div v-else-if="modelValue && modelValue.length > minLengthText" class="mb-4" aria-live="polite">
          No results for "<span class="font-bold">{{ modelValue }}</span>"
        </div>
        <!-- Placeholder status element when no search or results -->
        <div v-else>
          <!-- Initially empty to ensures the element is in DOM before content changes -->
        </div>
      </div>

      <!-- Slot for search results -->
      <div v-if="!loading && totalItems > 0">
        <slot name="results" v-bind="{ selected, onMouseOver }" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  ref, defineComponent, watch, computed,
} from 'vue';
import {
  string, object, func, number,
} from 'vue-types';

import SearchModalInput from './search-modal-input.vue';

export default defineComponent({
  components: {
    SearchModalInput,
  },

  props: {
    placeholder: string(),
    change: func<() => void>(),
    modelValue: string().def(''),
    debounce: number().def(300),
    class: string().def('max-w-screen-md mt-24'),
    error: object(),
    totalItems: number().def(0),
    loading: Boolean,
    minLengthText: number().def(0),
    closeModal: func().isRequired,
    onSelectByIndex: func<(index: number) => void>(),
    inputId: string(),
    inputAriaControl: string(),
    inputName: string(),
    label: string(),
  },
  emits: ['update:modelValue'],
  setup(properties, { emit }) {
    const show = ref(false);
    const selected = ref(0);
    const searchInput = ref(null);
    let timeout: ReturnType<typeof setTimeout>;

    const inputValue = computed({
      get: () => properties.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    watch(inputValue, () => {
      selected.value = 0;
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        if (properties.change) {
          properties.change();
        }
      }, properties.debounce);
    });

    const keyEvent = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowUp':
        case 'ArrowDown':
        case 'Enter':
          event.preventDefault();
          if (event.key === 'ArrowUp') {
            if (selected.value > 0) {
              selected.value -= 1;
            } else {
              selected.value = properties.totalItems - 1;
            }
          }
          if (event.key === 'ArrowDown') {
            if (selected.value < properties.totalItems - 1) {
              selected.value += 1;
            } else {
              selected.value = 0;
            }
          }
          if (properties.onSelectByIndex && event.key === 'Enter' && properties.totalItems > 0) {
            properties.onSelectByIndex(selected.value);
          }
          break;
        default: break;
      }
    };

    const onMouseOver = (_: HTMLButtonElement, index: number) => {
      selected.value = index;
    };

    return {
      show,
      searchInput,
      selected,
      onMouseOver,
      keyEvent,
      inputValue,
    };
  },
});
</script>
